<template>
  <section id="clusters-word-cloud">
    <vue-word-cloud
      v-if="words.length"
      style="height: 560px; width: 600px;"
      :words="words"
      :color="([, weight]) => getColor()"
      font-family="Montserrat"
    />

    <!-- <vue-word-cloud :words="words" v-if="words.length">
      <template slot-scope="{ word, score }">
        <div :title="score" style="cursor: pointer;">
          {{ word }}
        </div>
      </template>
    </vue-word-cloud> -->

    <!-- <wordcloud
      v-if="words.length"
      :data="words"
      nameKey="word"
      valueKey="score"
      color="Paired"
      :showTooltip="true"
    /> -->
  </section>
</template>

<script>
// import wordcloud from 'vue-wordcloud'

export default {
  name: 'ClustersWordCloud',
  props: {
    data: { type: Array, required: true }
  },
  data() {
    return {
      words: [],
      myColors: ['#6b5b95', '#feb236', '#d64161', '#ff7b25']
    }
  },
  components: {
    VueWordCloud: () => import('vuewordcloud')
    // wordcloud
  },
  watch: {
    data() {
      this.initWordCloud()
    }
  },
  methods: {
    async initWordCloud() {
      try {
        this.words = this.data.map(w => [w.word, w.score])
      } catch (error) {
        console.warn(error)
      }

      // this.words = words.map(w => [w.word, w.score])
      // this.words = words
    },
    getColor() {
      const index = Math.floor(Math.random() * (3 - 0 + 1) + 0)
      return this.myColors[index]
    }
  },
  mounted() {
    this.initWordCloud()
  }
}
</script>

<style lang="scss">
.con-vs-popup .vs-popup {
  width: 700px !important;
}
#clusters-word-cloud {
  min-width: 400px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
